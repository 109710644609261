<template>
    <div class="page bg-white">
        <div v-loading="loading"
             class="print-template-detail">
            <div style="padding:10px 0px">
                <el-button type="primary"
                           @click="perviewClick"
                           class="el-button el-button--default el-button--small">预览
                </el-button>
                <el-button @click="saveClick"
                           v-if="data.status==0"
                           type="primary"
                           class="el-button el-button--primary el-button--small">保存
                </el-button>
            </div>

            <div class="print-template-detail__body">
                <!-- 客户管理导航 -->
                <div class="system-view-nav">
                    <el-tabs v-model="activeTab"
                             @tab-click="tabsClick">
                        <el-tab-pane v-for="(item) in tabs"
                                     :key="item.keys"
                                     :label="item.label"
                                     :name="item.keys"/>
                    </el-tabs>
                    <!-- <reminder content="点击字段插入值（光标所在位置）"
                          class="xr-reminder" /> -->
                    <div class="menus">
                        <div v-for="(item, index) in menuList"
                             :key="index"
                             :class="{'is-select' : item.fieldName == menuIndex}"
                             class="menu-item"
                             @click="menuSelect(item, index)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="system-view-content">
                    <vue-tinymce ref="createTinymce"
                                 id="tinymce"
                                 v-model="content"
                                 :setup="setup"
                                 :setting="setting"/>

                </div>
            </div>
        </div>
        <el-dialog title="预览"
                   :visible.sync="dialogVisible"
                   width="60%"
                   :before-close="handleClose">
            <div id="preview-div"
                 class="preview-div"
                 v-html='content'></div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        declarationFormTemplateGetApi,
        declarationFormTemplateUpdateApi,
    } from '@/api/declaration/declarationFormTemplate'

    import {
        declarationFormTemplateDictTypeItemsApi,
        declarationFormTemplateDictValueItemsApi,
    } from '@/api/declaration/declarationFormTemplateDict'

    export default {
        data() {
            return {
                loading: false,
                dialogVisible: false,
                activeTab: '',
                tabs: [],
                tabData: {},
                menuIndex: '',
                richHeight: document.documentElement.clientHeight - 135,
                content: '',
                data: {},
                toolbar:
                    'fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen | importword', //工具栏
                // 控制选择的内容
                selectNodes: null,
                setting: this.getEditConfig(),
            }
        },

        computed: {
            getSetting() {
                return {
                    menubar: false,
                    toolbar:
                        'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | table | fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                    toolbar_drawer: 'sliding',
                    quickbars_selection_toolbar:
                        'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                    quickbars_insert_toolbar: false,
                    plugins: 'link image media table lists fullscreen quickbars',
                    language: 'zh_CN',
                    language_url:
                        './static/tinymce/langs/zh_CN.js',
                    height: document.documentElement.clientHeight - 135,
                    table_default_styles: {
                        width: '100%',
                        margin: '0 auto',
                    },
                    content_style: 'table{margin:0 auto !important;max-width:650px}',
                }
            },
            menuList() {
                return this.tabData[this.activeTab] || []
            },

            // 模板类型
            type() {
                return this.$route.query.type
            },

            isCreate() {
                return this.$route.query.handle == 'create'
            },

            editor() {
                return this.$refs.createTinymce.editor
            },
        },

        created() {
            this.getFields()
            this.getDetail()
        },

        methods: {
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then((_) => {
                        done()
                    })
                    .catch((_) => {
                    })
            },
            /** *
             * 获取详情
             */
            getDetail() {
                declarationFormTemplateGetApi(this.$route.query.id)
                    .then((res) => {
                        const data = res.data || {}
                        this.data = data
                        this.content = data.content || ''
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },

            /**
             *获取字段数据
             */
            getFields() {
                this.loading = true

                declarationFormTemplateDictValueItemsApi().then((res) => {
                    const data = res.data || {}
                    this.tabData = data
                    // 获取类型
                    declarationFormTemplateDictTypeItemsApi().then((res) => {
                        if (res && res.status) {
                            this.tabs = res.data
                            if (this.tabs.length > 0) {
                                this.activeTab = this.tabs[0].keys
                                console.log(this.tabs)
                                console.log(this.activeTab)
                                console.log(this.tabData[this.activeTab])
                            } else {
                                this.activeTab = ''
                            }
                            this.loading = false
                        }
                    })
                })
            },

            /** 左侧操作 */
            tabsClick() {
                this.menuIndex = ''
            },

            menuSelect(item, index) {
                this.editor.insertContent(this.getSpanNode(item, ''))
            },

            /**
             * 根据选择器获取父元素
             */
            getCurrentParentByTag(tag) {
                return this.editor.dom.getParent(this.editor.selection.getNode(), tag)
            },

            /**
             * 获取产品表
             */
            getProuctTable(item) {
                return `<table data-wk-table-tag="table" style="border-collapse: collapse; width: 100%;" border="1" >
      <tbody>
        <tr data-wk-table-tr-tag="header"><td data-wk-table-td-tag="name">${
                    item.name
                    }</td></tr>
        <tr data-wk-table-tr-tag="value"><td data-wk-table-td-tag="value">${this.getSpanNode(
                    item,
                    'table-value'
                )}</td></tr>
      </tbody>
      </table>`
            },

            /**
             * 获取基础span属性
             */
            getSpanNodeAttrs(item, tag = '') {
                return this.getSpanNodeAttrsByType(item, tag, this.activeTab)
            },

            getSpanNodeAttrsByType(item, tag = '', type) {
                const attrs = {}
                const dataKey = `data-wk${tag ? `-${tag}` : ''}-tag`
                const dataValue = `${type}.${item.fieldName}`

                attrs[dataKey] = dataValue
                attrs.class = `wk-print-tag-wukong ${this.getSpanColorClass()}`
                attrs.contenteditable = false
                return attrs
            },

            getSpanNode(item, tag = '', type = this.activeTab) {
                return `<span data-wk${tag ? `-${tag}` : ''}-tag="${type}.${
                    item.fieldName
                    }" class="wk-print-tag-wukong ${this.getSpanColorClass()}" contenteditable="false">{${
                    item.name
                    }}</span>`
            },

            /**
             * span 颜色
             */
            getSpanColorClass() {
                const color = [
                    'customer',
                    'contacts',
                    'business',
                    'contract',
                    'receivables',
                    'product',
                ].includes(this.activeTab)
                    ? this.activeTab
                    : 'common'
                return `wk-tiny-color--${color}`
            },

            /** 顶部操作 */

            /**
             * 预览
             */
            perviewClick() {
                this.dialogVisible = true
            },

            /**
             * 保存
             */
            saveClick() {
                this.loading = true
                const request = declarationFormTemplateUpdateApi
                console.log('content 保存时的内容', this.content)
                const params = {
                    id: this.data.id,
                    name: this.data.name,
                    type: this.data.type,
                    content: this.content,
                }
                request(params)
                    .then((res) => {
                        this.loading = false
                        this.$message({
                            type: 'success',
                            message: '保存成功',
                        })
                        this.$router.go(-1)
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },

            /**
             * 保存
             */
            goBackClick() {
                this.$router.go(-1)
            },

            /** 富文本配置 */
            getEditConfig() {
                return {
                    menubar: false,
                    toolbar_sticky: true,
                    statusbar: false,
                    toolbar:
                        'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | table | fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat | pagebreak |',
                    toolbar_drawer: 'sliding',
                    quickbars_selection_toolbar:
                        'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                    quickbars_insert_toolbar: false,
                    plugins: 'link image media table lists fullscreen quickbars pagebreak',
                    language: 'zh_CN',
                    language_url:
                        './static/tinymce/langs/zh_CN.js',
                    height: document.documentElement.clientHeight,
                    // extended_valid_elements: 'span[class|title|wktag|style|contenteditable]',
                    content_style: 'p { margin: 5px 0; line-height: 1.5;}',
                    content_css: [
                        './static/tinymce/css/tiny-wk-colors.css',
                        './static/tinymce/css/tiny-wk-word.css',
                    ],
                    table_advtab: false,
                    table_cell_advtab: false,
                    table_row_advtab: false,
                    setup: (editor) => {
                        editor.on('init', function (e) {
                            editor.execCommand('mceFocus')
                        })
                        editor.on('keydown', (e) => {
                            this.selectNodes = null
                            if (
                                e.keyCode === 8 /* Backspace key */ ||
                                /* del key */ e.keyCode == 46
                            ) {
                                const currentNode = editor.selection.getNode()
                                if (
                                    currentNode.getAttribute('data-wk-table-td-tag') === 'value'
                                ) {
                                    e.preventDefault()
                                    return false
                                } else if (currentNode.nodeName == 'SPAN') {
                                    if (currentNode.hasAttribute('contenteditable')) {
                                        e.preventDefault()
                                        editor.dom.remove(currentNode)
                                        return false
                                    } else {
                                        const farterSpan = this.getCurrentParentByTag(
                                            'span[contenteditable]'
                                        )
                                        if (farterSpan) {
                                            e.preventDefault()
                                            editor.dom.remove(farterSpan)
                                            return false
                                        }
                                    }
                                }
                            } else if (e.keyCode == '65' && (e.metaKey || e.ctrlKey)) {
                                // 全选
                                this.selectNodes = editor.selection.getNode()
                                this.setSpanEditAttr(this.selectNodes, true)
                            } else {
                                // table 里面不允许写内容
                                const currentNode = editor.selection.getNode()
                                if (
                                    currentNode.getAttribute('data-wk-table-td-tag') === 'value'
                                ) {
                                    // 只允许输入上下左右
                                    if (
                                        e.keyCode !== 37 &&
                                        e.keyCode !== 38 &&
                                        e.keyCode !== 39 &&
                                        e.keyCode !== 40
                                    ) {
                                        e.preventDefault()
                                        return false
                                    }
                                } else if (currentNode.nodeName == 'SPAN') {
                                    if (currentNode.hasAttribute('contenteditable')) {
                                        if (currentNode.getAttribute('contenteditable')) {
                                            e.preventDefault()
                                            currentNode.setAttribute('contenteditable', false)
                                            return false
                                        }
                                        return false
                                    } else {
                                        const farterSpan = this.getCurrentParentByTag(
                                            'span[contenteditable]'
                                        )
                                        if (farterSpan) {
                                            if (farterSpan.getAttribute('contenteditable')) {
                                                e.preventDefault()
                                                farterSpan.setAttribute('contenteditable', false)
                                                return false
                                            }
                                            return false
                                        }
                                    }
                                }

                                if (
                                    e.keyCode == 37 &&
                                    e.keyCode == 38 &&
                                    e.keyCode == 39 &&
                                    e.keyCode == 40
                                ) {
                                    // console.log('上下左右')
                                    this.cancelSpanEdit()
                                }
                            }
                        })

                        // editor.on('focusin', function(e) {
                        //   console.log('focusin')
                        // })

                        // editor.on('focusout', function(e) {
                        //   console.log('focusout')
                        // })

                        // editor.on('click', function(e) {
                        //   console.log('click')
                        // })

                        // editor.on('blur', (e) => {
                        //   console.log('blur')
                        // })

                        editor.on('mousedown', (e) => {
                            this.cancelSpanEdit()
                        })

                        editor.on('mouseup', (e) => {
                            const selection = editor.selection.getSel()
                            // console.log(selection.isCollapsed, selection.anchorNode == selection.focusNode, selection.anchorOffset == selection.focusOffset)

                            if (
                                e.target.hasAttribute('contenteditable') &&
                                selection.anchorOffset == 1 &&
                                selection.anchorOffset == 1
                            ) {
                                // 忽略
                            } else if (
                                e.target.hasAttribute('contenteditable') &&
                                selection.isCollapsed
                            ) {
                                this.cancelSpanEdit()
                            } else {
                                if (!selection.isCollapsed) {
                                    this.selectNodes = editor.selection.getNode()
                                    this.setSpanEditAttr(this.selectNodes, true)
                                } else {
                                    this.cancelSpanEdit()
                                }
                            }
                        })
                    },
                }
            },
            /**
             * 取消编辑
             */ cancelSpanEdit() {
                if (this.selectNodes) {
                    setTimeout(() => {
                        const selection = this.editor.selection.getSel()
                        if (!selection.isCollapsed) {
                            this.setSpanEditAttr(this.selectNodes, false)
                            this.selectNodes = null
                        }
                    }, 300)
                }
            },
            setup(editor) {
                editor.on('init', function (e) {
                    editor.execCommand('mceFocus')
                })
                editor.on('keydown', (e) => {
                    this.selectNodes = null
                    if (
                        e.keyCode === 8 /* Backspace key */ ||
                        /* del key */ e.keyCode == 46
                    ) {
                        const currentNode = editor.selection.getNode()
                        if (currentNode.getAttribute('data-wk-table-td-tag') === 'value') {
                            e.preventDefault()
                            return false
                        } else if (currentNode.nodeName == 'SPAN') {
                            if (currentNode.hasAttribute('contenteditable')) {
                                e.preventDefault()
                                editor.dom.remove(currentNode)
                                return false
                            } else {
                                const farterSpan = this.getCurrentParentByTag(
                                    'span[contenteditable]'
                                )
                                if (farterSpan) {
                                    e.preventDefault()
                                    editor.dom.remove(farterSpan)
                                    return false
                                }
                            }
                        }
                    } else if (e.keyCode == '65' && (e.metaKey || e.ctrlKey)) {
                        // 全选
                        this.selectNodes = editor.selection.getNode()
                        this.setSpanEditAttr(this.selectNodes, true)
                    } else {
                        // table 里面不允许写内容
                        const currentNode = editor.selection.getNode()
                        if (currentNode.getAttribute('data-wk-table-td-tag') === 'value') {
                            // 只允许输入上下左右
                            if (
                                e.keyCode !== 37 &&
                                e.keyCode !== 38 &&
                                e.keyCode !== 39 &&
                                e.keyCode !== 40
                            ) {
                                e.preventDefault()
                                return false
                            }
                        } else if (currentNode.nodeName == 'SPAN') {
                            if (currentNode.hasAttribute('contenteditable')) {
                                if (currentNode.getAttribute('contenteditable')) {
                                    e.preventDefault()
                                    currentNode.setAttribute('contenteditable', false)
                                    return false
                                }
                                return false
                            } else {
                                const farterSpan = this.getCurrentParentByTag(
                                    'span[contenteditable]'
                                )
                                if (farterSpan) {
                                    if (farterSpan.getAttribute('contenteditable')) {
                                        e.preventDefault()
                                        farterSpan.setAttribute('contenteditable', false)
                                        return false
                                    }
                                    return false
                                }
                            }
                        }

                        if (
                            e.keyCode == 37 &&
                            e.keyCode == 38 &&
                            e.keyCode == 39 &&
                            e.keyCode == 40
                        ) {
                            // console.log('上下左右')
                            this.cancelSpanEdit()
                        }
                    }
                })

                // editor.on('focusin', function(e) {
                //   console.log('focusin')
                // })

                // editor.on('focusout', function(e) {
                //   console.log('focusout')
                // })

                // editor.on('click', function(e) {
                //   console.log('click')
                // })

                // editor.on('blur', (e) => {
                //   console.log('blur')
                // })

                editor.on('mousedown', (e) => {
                    this.cancelSpanEdit()
                })

                editor.on('mouseup', (e) => {
                    const selection = editor.selection.getSel()
                    // console.log(selection.isCollapsed, selection.anchorNode == selection.focusNode, selection.anchorOffset == selection.focusOffset)

                    if (
                        e.target.hasAttribute('contenteditable') &&
                        selection.anchorOffset == 1 &&
                        selection.anchorOffset == 1
                    ) {
                        // 忽略
                    } else if (
                        e.target.hasAttribute('contenteditable') &&
                        selection.isCollapsed
                    ) {
                        this.cancelSpanEdit()
                    } else {
                        if (!selection.isCollapsed) {
                            this.selectNodes = editor.selection.getNode()
                            this.setSpanEditAttr(this.selectNodes, true)
                        } else {
                            this.cancelSpanEdit()
                        }
                    }
                })
            },

            /**
             * 取消编辑
             */
            cancelSpanEdit() {
                if (this.selectNodes) {
                    setTimeout(() => {
                        const selection = this.editor.selection.getSel()
                        if (!selection.isCollapsed) {
                            this.setSpanEditAttr(this.selectNodes, false)
                            this.selectNodes = null
                        }
                    }, 300)
                }
            },

            /**
             * 操控span是否可编辑
             */
            setSpanEditAttr(node, canEdit) {
                if (node && node.hasAttribute('contenteditable')) {
                    if (node.getAttribute('contenteditable') != canEdit) {
                        node.setAttribute('contenteditable', canEdit)
                    }
                }

                if (node && node.children) {
                    for (let index = 0; index < node.children.length; index++) {
                        const element = node.children[index]
                        if (element.children) {
                            this.setSpanEditAttr(element, canEdit)
                        } else if (element.hasAttribute('contenteditable')) {
                            if (node.getAttribute('contenteditable') != canEdit) {
                                element.setAttribute('contenteditable', canEdit)
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .print-template-detail {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        &__body {
            flex: 1;
            position: relative;
            display: flex;
            overflow: hidden;
        }
    }

    .system-view-nav {
        width: 300px;
        position: relative;
        background: #fff;
        margin-right: 10px;
        padding-top: 10px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        .el-tabs {
            padding: 0 5px;
            border-bottom: 1px solid #e4e7ed;

            .el-tabs__nav-wrap::after {
                display: none;
            }

            .el-tabs__header {
                margin-bottom: 0;
            }
        }
    }

    .system-view-content {
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        background: #fff;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
    }

    // 菜单
    .menus {
        height: calc(100% - 90px);
        overflow-y: auto;
    }

    .menu-item {
        color: #333;
        font-size: 13px;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        position: relative;

        .icon-close {
            position: absolute;
            top: 0;
            right: 8px;
            z-index: 1;
            display: none;
        }
    }

    .menu-item:hover,
    .menu-item.is-select {
        background-color: #f6f8fa;
    }

    .menu-item:hover::before,
    .menu-item.is-select::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        background-color: #5383ed;
    }

    .xr-reminder {
        .reminder-body {
            align-items: stretch;
            width: 100%;
            height: 35px;
            line-height: 35px;
            border-radius: 0;
        }
    }

    .preview-div {
        ::v-deep .MsoNormalTable {
            margin: 0 auto !important;
            border: 1px solid #000000 !important;
        }

        ::v-deep tr td {
            border: 1px solid #000000 !important;
        }
    }
</style>
