import { request } from '@/utils/httpRequest'


/**
 * 申报书模板字典类型列表 api
 * @param {current} current 
 * @param {size} size 
 * @param {描述} description 
 * @returns 
 */
export function declarationFormTemplateDictTypesApi(current, size, description) {
  var param = {
    current: current,
    size: size,
    description: description
  }
  return request('zyd-catalogue/declaration/form/template/dict/types', param, 'get')
}

/**
 * 申报书模板字典类型列表 api
 * @returns 
 */
export function declarationFormTemplateDictTypeItemsApi() {
  return request('zyd-catalogue/declaration/form/template/dict/typeItems', undefined, 'get')
}
/**
 * 申报书模板字典类型列表 api
 * @returns 
 */
export function declarationFormTemplateDictValueItemsApi() {
  return request('zyd-catalogue/declaration/form/template/dict/valueItems', undefined, 'get')
}


/**
 * 申报书模板字典类型参数列表 api
 * @param {current} current 
 * @param {size} size 
 * @param {描述} description 
 * @returns 
 */
export function declarationFormTemplateDictValuesApi(dictTypeId) {
  return request('zyd-catalogue/declaration/form/template/dict/values/' + dictTypeId, undefined, 'get')
}

/**
 * 获取申报书模板类型 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictTypeGetApi(id) {
  return request('zyd-catalogue/declaration/form/template/dict/type/' + id, {}, 'get')
}

/**
 * 获取申报书模板类型参数 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictValueGetApi(id) {
  return request('zyd-catalogue/declaration/form/template/dict/value/' + id, {}, 'get')
}

/**
 * 新增申报书模板类型 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictTypeSaveApi(data) {
  return request('zyd-catalogue/declaration/form/template/dict/type/save', data, 'post')
}

/**
 * 修改申报书模板类型 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictTypeUpdateApi(data) {
  return request('zyd-catalogue/declaration/form/template/dict/type/update', data, 'post')
}

/**
 * 删除申报书模板类型 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictTypeDeleteApi(id) {
  return request('zyd-catalogue/declaration/form/template/dict/type/delete/' + id, {}, 'delete')
}


/**
 * 新增申报书模板类型参数 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictValueSaveApi(data) {
  return request('zyd-catalogue/declaration/form/template/dict/value/save', data, 'post')
}

/**
 * 修改申报书模板类型参数 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictValueUpdateApi(data) {
  return request('zyd-catalogue/declaration/form/template/dict/value/update', data, 'post')
}

/**
 * 删除申报书模板类型参数 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDictValueDeleteApi(id) {
  return request('zyd-catalogue/declaration/form/template/dict/value/delete/' + id, {}, 'delete')
}

