import { request } from '@/utils/httpRequest'

/**
 * 申报书模板列表 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateListApi(data) {
  return request('zyd-catalogue/declaration/form/template/list', data, 'get')
}

/**
 * 新增申报书模板 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateSaveApi(data) {
  return request('zyd-catalogue/declaration/form/template/save', data, 'post')
}

/**
 * 修改申报书模板 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateUpdateApi(data) {
  return request('zyd-catalogue/declaration/form/template/update', data, 'post')
}

/**
 * 删除申报书模板 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateDeleteApi(id) {
  return request('zyd-catalogue/declaration/form/template/delete/' + id, {}, 'delete')
}

/**
 * 获取申报书模板 API
 * @param {参数} data 
 * @returns 
 */
export function declarationFormTemplateGetApi(id) {
  return request('zyd-catalogue/declaration/form/template/' + id, {}, 'get')
}

/**
 * 根据模板编号获取模板参数对应的权限分配数据列表数据
 * @param {模板编号} templateId 
 * @returns 
 */
export function declarationFormTemplateAuthorityList(templateId) {
  return request('zyd-catalogue/declaration/form/template/authority/list/' + templateId, {}, 'get')
}

/**
 * 更新数据
 * @param {数据} data 
 */
export function declarationFormTemplateAuthorityUpdate(data) {
  return request('zyd-catalogue/declaration/form/template/authority/update', data, 'post')
}

/**
 * 发布
 * @param {模板编号} id 
 */
export function declarationFormTemplateReleaseApi(id, exhibitionId) {
  let param = {
  }
  return request('zyd-catalogue/declaration/form/template/release/' + id + "/" + exhibitionId, param, 'post')
}


/**
 * 已发布的列表
 * @param {*} current 
 * @param {*} size 
 * @param {*} searchStr 
 * @returns 
 */
export function declarationFormTemplateReleaseListApi(current, size, searchStr) {
  var param = {
    current: current,
    size: size,
    searchStr: searchStr
  }
  return request('zyd-catalogue/declaration/form/template/releaseList', param, 'get')
}

/**
 * 获取模板中参数权限列表
 * @param {模板编号} templateId 
 */
export function declarationFormTemplateReleaseAuthorityListApi(templateId) {
  var param = {
    templateId: templateId,
  }
  return request('zyd-catalogue/declaration/form/template/release/authority/list', param, 'get')
}

/**
 * 获取模板中参数权限列表 保存
 * @param {模板编号} templateId 
 */
export function declarationFormTemplateReleaseAuthorityListSaveApi(declarationFormTemplateDictAuthorities) {
  return request('zyd-catalogue/declaration/form/template/release/authority/list/save', declarationFormTemplateDictAuthorities, 'post')
}


export function declarationFormTemplatePreviewApi(content, type) {
  var param = {
    "content": content,
  }

  return request('zyd-catalogue/declaration/form/template/preview/' + type, param, 'post', 'blob')
}
/**
 * 通过path 下载
 * @param {*} data
 */
export function crmFileDownByPathAPI(content) {
  content.replace(/^\"|\"$/g, '');
  var param = {
    content: content,
  }
  console.log(param);
  return request({
    url: 'zyd-catalogue/declaration/form/template/preview',
    method: 'get',
    data: param,
    responseType: 'blob'
  })
}

export function downloadFileWithBuffer(data, name, type) {
  var blob = new Blob([data], {
    type: type || ''
  })
  console.log(data);
  var downloadElement = document.createElement('a')
  var href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = name // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
}


export function declarationFormTemplateDocumentInspection(id) {
  return request('zyd-catalogue/declaration/form/template/document/inspection/' + id, undefined, 'post')
}



